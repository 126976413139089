<div class="home-container">
      <div class="content-area">
        <h3>Welcome to the VES GTIX API Validator</h3>

        <p>
        API Validator is being created to help VMware teams improve the quality and consistency of their APIs. API Validator
          allows teams to easily check REST API compliance with <a href="http://go/rest" target="_blank">VMware REST standards</a>
          as well as custom standards adopted by individual products and teams.  API definitions can be validated at development
          time using this Web UI and as a part of automated CICD flows via the API Validator Services or associated scripts and tools.
        </p>
        <p>
        We in GTIX really feel that the API Validator tool should be "internal open-source" (if not a public open-source project eventually).
          As such, if there are features you want, get involved!  Contribute some engineering bandwidth and improve the tool.
          Please contact <a href="mailto:aspear@vmware.com">Aaron Spear</a> if you are interested.
        </p>

        <h4>CICD integration</h4>
        <p>
          If you wish to incorporate validation into your CICD cycles, you can do so using the API Validator web services. In the <a routerLink="/developer/downloads" routerLinkActive="active" class="nav-link nav-text">Developer Center</a>
          you can find downloads of example command line tools that use the API as well as an <a routerLink="/developer/api-explorer" routerLinkActive="active" class="nav-link nav-text">API Explorer</a>.
        </p>
        <p>
        You can also watch the Tech Talk on <a href="https://tech-talks.eng.vmware.com/talks/integrating-api-validation-into-your-development-and-release-workflow" target="_blank">Integrating API Validation into your Development and Release Workflow</a> available.
        </p>

        <h4>if (Questions || Feature_Requests || Issues)</h4>
        This tool is new!  Please help us improve it.  You can:
        <ol>
          <li>Click on the Provide Feedback option on the header and tell us what you think!</li>
          <li><a href="https://vmware.slack.com/messages/CLNBJF6TU" target="_blank">Join the conversation on the #api-validator Slack channel.</a></li>
          <li>Please enter any bugs or feature requests in the <a href="https://jira.eng.vmware.com/secure/RapidBoard.jspa?projectKey=APIV&rapidView=7245&view=planning" target="_blank">APIV Jira backlog.</a></li>
          <li>You can email the development team at <a href="mailto:api-validator-owners@vmware.com">api-validator-owners@vmware.com</a></li>
          <li>You can check out the sources at <a href="https://gitlab.eng.vmware.com/gtix-tools/api-validator" target="_blank">https://gitlab.eng.vmware.com/gtix-tools/api-validator</a>.</li>
        </ol>

        <p>
          You can find background and the roadmap on the <a href="https://confluence.eng.vmware.com/display/apiv/API+Validator" target="_blank">API Validator Confluence page</a>.
        </p>
      </div>
  </div>
