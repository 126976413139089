/**
 * Copyright 2018-2020VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalysisService } from '../../services/analysis.service';
import {HttpClient} from '@angular/common/http';
import {VmwClarityTheme, VmwClarityThemeService} from '@vmw/ngx-utils';

@Component({
  selector: 'vmw-apiv-config-display',
  templateUrl: './config-display.component.html',
  styleUrls: ['./config-display.component.css'],
})

export class ConfigDisplayComponent implements OnInit {

  public VmwClarityTheme = VmwClarityTheme;

  configName: string;
  fileType: string;
  fileContent: string;
  line: number;

  editor: any;

  lightEditorOptions = {automaticLayout : true, theme: 'vs-light', language: 'text', readOnly: true };
  darkEditorOptions = {automaticLayout : true, theme: 'vs-dark', language: 'text', readOnly: true };

  constructor(private analysisService: AnalysisService,
              private http: HttpClient,
              private route: ActivatedRoute,
              public themeService: VmwClarityThemeService) {

    this.route.paramMap.subscribe(params => {
      this.configName = params.get('configName');
      this.fileType = params.get('fileType');

      this.displayFile();
    });

    this.route.queryParams.subscribe(params => {
      const line = params['line'];
      if (line) {
        this.line = parseInt(line,10);
      }
    });
  }

  ngOnInit() {
  }

  onInit(editor) {
      this.editor = editor;
  }

  scrollToLine(line: number) {
    if (this.editor) {
      this.editor.revealLineInCenter(line);
      var decorations = this.editor.deltaDecorations([], [
        { range: new monaco.Range(line,1,line,200), options: { isWholeLine: true, linesDecorationsClassName: 'myLineDecoration' }},
        { range: new monaco.Range(line,1,line,200), options: { inlineClassName: 'myInlineDecoration' }},
      ]);
    }
  }

  displayFile(): void {
    if ('log' === this.fileType) {
      this.lightEditorOptions.language = 'text';
    } else if ('html' === this.fileType) {
      this.lightEditorOptions.language = 'html';
    } else if ('junit' === this.fileType) {
      this.lightEditorOptions.language = 'xml';
    } else if ('yaml' === this.fileType) {
      this.lightEditorOptions.language = 'yaml';
    } else {
      this.lightEditorOptions.language = 'json';
    }
    this.darkEditorOptions.language = this.lightEditorOptions.language;

    this.fileContent = null;

    const url = this.analysisService.getConfigFileUrl(this.configName);

    this.http.get(url,{responseType:'text'}).subscribe(
      response => {
        this.fileContent = response as string;

        if (this.line) {
          this.scrollToLine(this.line);
        }
      }
    );
  }
}
