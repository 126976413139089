/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AnalysisService } from '../../services/analysis.service';
import { StandardService } from '../../services/standard.service';
import { ApivValidationJob} from '../../../apiv-client/Apiv/types';
import { ApivValidationResults} from '../../../apiv-client/Apiv/types';
import { ApivValidationEntry} from '../../../apiv-client/Apiv/types';
import { Observable} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'vmw-apiv-job-display',
  templateUrl: './job-display.component.html',
  styleUrls: ['./job-display.component.css'],
})

export class JobDisplayComponent implements OnInit, OnChanges {

  errorEditorOptions = {automaticLayout : true, theme: 'vs-light', language: 'text', readOnly: true };

  @Input() jobId: string;
  @Input() pendingRequest: boolean;

  pendingResults: boolean;
  validationJob: ApivValidationJob;
  jobLogUrl: string;
  jobSpecUrl: string;
  jobConfigUrl: string;
  jobResultsUrl: string;

  analysis: any;
  resultsErrorMsg: string = null;
  resultsErrorCode = 0;
  resultsHtmlUrl: string;
  resultsJunitUrl: string;
  public showRickRoll = false; //aaron setting this to disabled for now because it shows when builds fail which is embarrasing.

  constructor(private analysisService: AnalysisService,
              private standardService: StandardService,
              private route: ActivatedRoute) {

    this.route.paramMap.subscribe(params => {
      console.log('job changed on the route: params=', params);
      const jobId = params.get('jobId');
      if (jobId) {
        this.syncValidation(jobId);
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
   /* const jobId: SimpleChange = changes.jobId;
    console.log('jobId prev value: ', jobId.previousValue);
    console.log('jobId new value : ', jobId.currentValue);
    this.jobId = jobId.currentValue;

    const pendingRequest: SimpleChange = changes.pendingRequest;
    console.log('pendingRequest prev value: ', pendingRequest.previousValue);
    console.log('pendingRequest new value : ', pendingRequest.currentValue);
    this._pendingRequest = pendingRequest.currentValue;*/
  }

  //  convert UTC ISO8601 timestamp to  2019-10-01T09:23:28
  //toLocalTimestamp(ts : string) {
  //  //return moment(ts).toISOString(true);
  //  const inDate = new Date(ts);
//
 //   const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds from UTC
 //   return (new Date(inDate - tzoffset)).toISOString().slice(0, -5);
 // }

  toDurationSeconds(start: string, finish: string) {

    if (start && finish) {
      const startMoment = moment(start);
      const finishMoment = moment(finish);
      const duration = moment.duration(finishMoment.diff(startMoment));
      return duration.asSeconds().toString();
    } else {
      return "Running...";
    }
    //const sd = new Date(start);
    //const fd = new Date(finish);
    //const diff = fd - sd;
    //return diff.toString();
  }

  getDuplicateOfHtml( job: ApivValidationJob): string {
    return this.analysisService.getDuplicateOfHtml(job.duplicate_of_id);
  }

  getEntryMessageHtml( entry: ApivValidationEntry): string {
    if (entry.validation_name) {
      const url = this.standardService.getRequirementUrl(entry.validation_name);
      if (url) {
        const returnHtml = '<a href="' + url + '" target="_blank">' + entry.message + '</a>';
        return returnHtml;
      }
    }
    return entry.message;
  }

  getSourceLineUrl(file: string, line?: number, column?: number): string {
    return this.analysisService.getSpecUrl(this.jobId, line);
  }

  syncValidation(jobId: string): void {

    console.log('JobDisplayComponent.syncValidation  this.jobId=' + this.jobId + ' jobId=' + jobId);

    if (jobId) {
      this.jobId = jobId;
    }

    this.pendingRequest = false;
    this.pendingResults = false;
    this.resultsErrorMsg = null;
    this.resultsErrorCode = 0;

    this.analysis = null;
    this.validationJob = null;
    this.resultsHtmlUrl = null;
    this.resultsJunitUrl = null;
    this.jobLogUrl = null;
    this.jobSpecUrl = null;
    this.jobConfigUrl  = null;
    this.jobResultsUrl = null;

    if (!this.jobId) {
      console.log('JobDisplayComponent.syncValidation: jobId is null, nothing to display.');
      return;
    }

    this.pendingRequest = true;

    console.log('JobDisplayComponent.syncValidation: requesting job status for: ', this.jobId);

    this.analysisService.pollForFinishedStatus(this.jobId).subscribe(
      response => {
        const job = response as ApivValidationJob;
        console.log('GOT JOB STATUS: ', job);
        this.validationJob = job;

        this.pendingRequest = false;

        this.jobLogUrl = this.analysisService.getLogUrl(this.validationJob.id);
        this.jobSpecUrl = this.analysisService.getSpecUrl(this.validationJob.id);
        this.jobConfigUrl = this.analysisService.getConfigUrl(this.validationJob.id);
        this.jobResultsUrl = this.analysisService.getJobResultsUrl(this.validationJob.id);

        if (this.validationJob.job_error == null) {

          this.resultsHtmlUrl = this.analysisService.getHtmlReportUrl(this.validationJob.id);
          this.resultsJunitUrl = this.analysisService.getJunitReportUrl(this.validationJob.id);

          this.pendingResults = true;

          this.analysisService.getResults(this.jobId).subscribe(
            resultResponse => {
              const results = resultResponse as ApivValidationResults;

              this.pendingResults = false;

              //console.log('GOT RESULTS: ', results);
              this.analysis = results;
              this.pendingRequest = false;

              // these lines are just for fun, an easter egg for people that have perfect specs.
              const containerObj: JobDisplayComponent = this;
              setTimeout(function () {
                console.log('hiding image');
                containerObj.showRickRoll = false;
              }, 2000);
            }, error => {
              this.pendingRequest = false;
              this.pendingResults = false;
              if ((typeof error === 'string')) {
                this.resultsErrorMsg = 'While loading results:\n' + error;
              } else {
                this.resultsErrorCode = error.status;
                this.resultsErrorMsg = error.message;
              }
              console.log('ERROR requesting results: ', error);
            });
        } else {
          // there was an error.
          this.pendingRequest = false;
          this.pendingResults = false;
        }
      }
    );
  }

  private downloadJunitXmlReport() {
    this.analysisService.getJunitReportBlob(this.validationJob.id).subscribe(response => {

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([(response)], { type: 'text/xml' });

      const downloadURL = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.validationJob.id + '-junit.xml';
      link.click();
    });
  }
  private downloadHtmlReport() {
    this.analysisService.getHtmlReportBlob(this.validationJob.id).subscribe(response => {

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([(response)], {type: 'text/html'});

      const downloadURL = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.validationJob.id + '-report.html';
      link.click();
    });
  }


}
