
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Observable ,  Subject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


export interface ZipFileEntry {
  name: string;
  location: string;
}

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styles: [`
    .filter-files {
      text-align: right;
    }
    .filter-files input[type=text]{
      width: 180px !important;
    }
  `],
})
export class FileTableComponent implements OnInit {

  @Input()
  files: ZipFileEntry[];
  filteredFiles: ZipFileEntry[];

  @Output()
  selectTableFile = new EventEmitter<ZipFileEntry>();

  selectedFile: ZipFileEntry;

  filterSubject: Subject<string> = new Subject<string>();

  constructor() {
  }

  ngOnInit() {
    // in the beginning no file is filtered
    this.filteredFiles = this.files;
    // implementation to handle the file filtering
    this.filterSubject.pipe(debounceTime(500), distinctUntilChanged(), ).subscribe(fileName => {
      fileName = fileName.toLowerCase();
      this.filteredFiles = this.files.filter(dcrFile => {
        return dcrFile.name.toLowerCase().includes(fileName);
      });
    });
  }

  /**
   * This method captures which file is selected, and emit the event to the parent
   * @param event
   */
  selectFile(event) {
    if (event.target.type === "radio") {
      const location = event.currentTarget.getAttribute('data-location');
      const zipFile: ZipFileEntry = this.files.find(file => location === file.location);
      this.selectTableFile.emit(zipFile);
    }
  }

  onTypingFilter(event) {
    // emitt and event with the filter string
    this.filterSubject.next(event.target.value);
  }

}
