import { Component } from '@angular/core';
import {AppConfigService} from './services/app-config.service';

@Component({
  selector: 'vmw-apiv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public isProduction: boolean;
  public showProductionWarning = true;

  constructor(private appConfigService: AppConfigService) {
    this.isProduction = 'true' === this.appConfigService.config.isProduction;

    // we have a mechanism whereby if a user hides the warning, we do in fact hide it for the entirety of the current day.
    // it will then show up again tomorrow...
    const hideProductionDate = localStorage.getItem('HIDE_PRODUCTION_WARNING_DATE');
    const todaysDateString = this.getTodaysDateString();
    if (todaysDateString === hideProductionDate) {
      this.showProductionWarning = false;
    }
  }

  private getTodaysDateString() {
    const today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  }

  onChangeSource(event) {
  }

  gotoProduction() {
    window.location.href = 'https://api-validator.esp.vmware.com';
  }

  hideProductionWarning() {
    this.showProductionWarning = false;
    localStorage.setItem('HIDE_PRODUCTION_WARNING_DATE', this.getTodaysDateString());
  }
}
