import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { filter, switchMap, take, finalize, catchError } from 'rxjs/operators';

import { UserService } from './user.service';
import { AuthService } from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refreshingToken: boolean;

    constructor(private userService: UserService, private authService: AuthService, private configService: AppConfigService) {
    }

    // FIXME this is pretty much a HACK for now.  We cannot and should not inject this auth on every single
    // API request.  Instead of doing this, there should perhaps be an opt in list in the config of servers
    // to inject out token auth into.
    private isIgnoredUrl(url: string): boolean {
      if (url.endsWith('/assets/config.json')) {
        return true;
      }
      if (url.endsWith('/tokens')) {
        return true;
      }
      if (url.startsWith('https://vdc-download')) {
        return true;
      }
      if (url.startsWith('https://vdc-repo')) {
        return true;
      }
      // if (this.configService.config) {
      //   if (this.configService.config.codeDcrUrl && url.startsWith(this.configService.config.codeDcrUrl)) {
      //     return true;
      //   }
      //   if (this.configService.config.codeSxUrl && url.startsWith(this.configService.config.codeSxUrl)) {
      //     return true;
      //   }
      // }
      return false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isIgnoredUrl(req.url)) {
            if (this.refreshingToken) {
                console.log("Hold request till refreshing token : " + req.url);
                return this.userService.getCurrentSession().pipe(
                    filter(session => session !== null),
                    take(1),
                    switchMap((session) => next.handle(this.addAuthorization(req, session.access_token)))
                );
            }
            const accessToken = this.userService.getAccessToken();
            if (!accessToken) {
                return next.handle(req);
            }
            if (this.userService.isTokenExpired(accessToken)) {
                console.log("Access Token is expired. Trying to refresh token...");
                this.refreshingToken = true;
                const refreshToken = this.userService.getRefreshToken();
                this.userService.clearCurrentSession();
                return this.authService.refreshToken(refreshToken).pipe(
                    finalize(() => this.refreshingToken = false),
                    switchMap(
                        (session: any) => {
                            console.log("Refreshed token ...")
                            this.userService.saveCurrentSession(session);
                            return next.handle(this.addAuthorization(req, session.access_token));
                        }
                    ),
                    catchError(error => {
                        console.log(error);
                        this.userService.redirectToLogin();
                        return throwError(error);
                    })
                );
            } else {
                return next.handle(this.addAuthorization(req, accessToken));
            }
        } else {
            return next.handle(req);
        }
    }

    addAuthorization(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
        return req.clone({ setHeaders: {Authorization: `Bearer ${accessToken}`}});
    }
}
