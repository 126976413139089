/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */
import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {map, flatMap, catchError} from 'rxjs/operators';
import * as JSZip from 'jszip';
import {AnalysisService} from '../../services/analysis.service';
import {ApivValidationConfigs} from '../../../apiv-client/Apiv/types/ApivValidationConfigs';
import {ApivValidationConfig} from '../../../apiv-client/Apiv/types/ApivValidationConfig';
import {VmwComboboxItem} from '@vmw/ngx-components';
import {ApivConfigWrapper} from '../../models/ApivConfigWrapper';

export enum ConfigType {
  SHARED,
  LOCAL_FILE,
}

const VALIDATION_CONFIG_NAME = 'VALIDATION_CONFIG_JSON_NAME';
const VALIDATION_CONFIG_LOCAL_JSON = 'VALIDATION_CONFIG_LOCAL_JSON';
const VALIDATION_CONFIG_LOCAL_ZIP = 'VALIDATION_CONFIG_LOCAL_ZIP';

// const VALIDATION_CONFIG_TYPE = 'VALIDATION_CONFIG_TYPE';

@Component({
  selector: 'vmw-apiv-choose-config',
  templateUrl: './choose-config.component.html',
  styleUrls: ['./choose-config.component.css'],
})
export class ChooseConfigComponent implements OnInit, OnChanges {

  // configurationType: string = "";
  selectedItem: VmwComboboxItem = null;
  items: Array<VmwComboboxItem> = [];
  configurationName = '';
  persistedLocalConfig: ApivValidationConfig = null;
  persistedConfigZipFile: File = null;
  persistedConfigZipFileConfigName = '';
  configs: ApivValidationConfig[];
  loading: boolean;
  errorMessage: string;

  @Output()
  onSelectConfig = new EventEmitter<ApivConfigWrapper>();

  constructor(private analysisService: AnalysisService) {
  }

  ngOnInit() {
    // console.log('ChooseConfigComponent.ngOnInit()');

    this.configurationName = localStorage.getItem(VALIDATION_CONFIG_NAME); // may be null

    // this code loads the last manually loaded configuration file from local storage
    const persistedLocalConfigJsonString = localStorage.getItem(VALIDATION_CONFIG_LOCAL_JSON);
    if (persistedLocalConfigJsonString) {
      this.persistedLocalConfig = JSON.parse(persistedLocalConfigJsonString);

      // sanity check on the this config.  Make SURE it has a name for instance.
      if (this.persistedLocalConfig && !this.persistedLocalConfig.name) {
        console.log('persisted config appears corrupted, clearing.');
        this.persistedLocalConfig = null;
        localStorage.removeItem(VALIDATION_CONFIG_NAME);
      }
    } else {
      this.persistedLocalConfig = null;

      const persistedLocalConfigZipBase64String = localStorage.getItem(VALIDATION_CONFIG_LOCAL_ZIP);
      if (persistedLocalConfigZipBase64String) {
        const zipFile = this.b64toFile('config.zip', persistedLocalConfigZipBase64String, 'application/zip');
        this.persistedConfigZipFile = zipFile;
        this.persistedConfigZipFileConfigName = this.configurationName;
        console.log('ngOnInit loaded persisted zip', this.persistedConfigZipFile);
      }
    }

    this.loadData();
  }

  ngOnChanges() {
    // console.log('ChooseConfigComponent.ngOnChanges()');
    this.loadData();
  }

  loadData() {
    // console.log('ChooseConfigComponent.loadData()');
    this.getSharedConfigs();
  }

  /**
   * Assumming that configs exists, this recreates the items list
   */
  recreateItems() {

    console.log('ChooseConfigComponent.recreateItems. this.configurationName="' + this.configurationName + '"');

    const itemArray = new Array<any>();

    if (this.persistedLocalConfig) {
      console.log('ChooseConfigComponent have persisted local config "' + this.persistedLocalConfig.name + '"');

      const configWrapper = new ApivConfigWrapper(null);
      configWrapper.configZipFile = null;
      configWrapper.apivConfig = this.persistedLocalConfig;
      configWrapper.name = this.persistedLocalConfig.name;
      const persistedLocalConfigItem = new VmwComboboxItem(this.persistedLocalConfig.name, configWrapper);
      itemArray.push(persistedLocalConfigItem);
    } else {
      if (this.persistedConfigZipFile) {
        const configWrapper = new ApivConfigWrapper(null);
        configWrapper.configZipFile = this.persistedConfigZipFile;
        configWrapper.apivConfig = null;
        configWrapper.name = this.persistedConfigZipFileConfigName;
        const persistedLocalConfigItem = new VmwComboboxItem(this.persistedConfigZipFileConfigName, configWrapper);
        itemArray.push(persistedLocalConfigItem);
      }
    }

    if (this.configs) {
      for (const config of this.configs) {
        const configWrapper = new ApivConfigWrapper(null);
        configWrapper.configZipFile = null;
        configWrapper.apivConfig = config;
        configWrapper.name = config.name;
        itemArray.push(new VmwComboboxItem(config.name, configWrapper));
      }
    }

    this.items = itemArray;

    for (const item of itemArray) {
      if (item.value.name === this.configurationName) {
        // console.log('Setting selection of last persisted config', item.value);
        this.selectedItem = item;
        this.onSelectConfig.emit(item.value);
      }
    }
  }

  getSharedConfigs() {
    // console.log('ChooseConfigComponent.getSharedConfigs()');
    this.loading = true;
    this.errorMessage = null;

    this.analysisService.getSharedConfigs()
      .subscribe((summaries: ApivValidationConfigs) => {
        // console.log('got shared configs', summaries);
        this.loading = false;

        this.configs = summaries.data; // Array<ApivValidationConfig>();

        // null out the validators section of the configs so that we can detect on the backend that it is a shared
        // config only.
        for (const config of this.configs) {
          config.validators = null;
          // console.log(config);
        }
        this.recreateItems();
      }, error => {
        this.loading = false;
        this.errorMessage = 'While loading configurations:\n' + ((typeof error === 'string') ? error : error.message);
        console.log('ERROR loading configurations: ', error);
      });
  }

  onItemSelected() {
    // console.log('ChooseConfigComponent.onItemSelected', this.selectedItem);
    if (this.selectedItem) {
      localStorage.setItem(VALIDATION_CONFIG_NAME, this.selectedItem.value.name);
      this.onSelectConfig.emit(this.selectedItem.value);
    }
  }

  file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  b64toFile(fileName: string, b64Data: string, contentType: string): File {
    console.log('b64toFile: b64Data=', b64Data);
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});

    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    const f: any = blob;
    f.lastModifiedDate = new Date();
    f.name = fileName;
    return <File>f;
  }

  onConfigFileChange(event) {
    if (event.target.files.length > 0) {

      const inputFile = event.target.files[0];
      const fileExtension = inputFile.name.split('.').pop();
      console.log('config file changed ', inputFile, 'extension=', fileExtension);

      // read the file and then set the persisted Config value appropriately
      if (fileExtension === 'zip') {
        console.log('Parsing zip config file');

        localStorage.removeItem(VALIDATION_CONFIG_LOCAL_JSON);
        // localStorage.removeItem(VALIDATION_CONFIG_NAME);
        this.configurationName = null;
        this.persistedLocalConfig = null;

        const jsZip: JSZip = new JSZip();
        jsZip.loadAsync(inputFile).then(zip => {
          zip.forEach(fileRef => {
            if (!zip.files[fileRef].dir) {
              const fileName: string = zip.files[fileRef].name;
              if (fileName.match('.*config.*\\.json')) {
                // this is the main config file, extract the content as json and then get the name
                zip.files[fileRef].async('string').then(data => {
                  const configInZip = new ApivValidationConfig(JSON.parse(data));
                  console.log('loaded config zip with file "' + fileName + '" name "' + configInZip.name + '"');

                  this.file2Base64(inputFile).then(result => {
                    console.log('got base64 zip', result);
                    localStorage.setItem(VALIDATION_CONFIG_LOCAL_ZIP, result);
                    localStorage.removeItem(VALIDATION_CONFIG_LOCAL_JSON);
                    localStorage.setItem(VALIDATION_CONFIG_NAME, configInZip.name);

                    this.persistedConfigZipFile = inputFile;
                    this.persistedConfigZipFileConfigName = configInZip.name;
                    this.configurationName = configInZip.name;

                    this.recreateItems();
                  });
                });
              }
            }
          });
        });
      } else {
        console.log('Parsing JSON config file');

        const fileReader = new FileReader();

        fileReader.onload = (e) => {

          let configJson;

          if (fileReader.result instanceof ArrayBuffer) {
            const dataView = new DataView(fileReader.result);
            const decoder = new TextDecoder('utf-8');
            const decodedString = decoder.decode(dataView);
            configJson = decodedString;
          } else {
            configJson = fileReader.result;
          }

          let newConfig;

          // NOTE: Aaron doesn't actually understand why this is coming as JSON sometimes and strings other times.
          if (typeof configJson === 'string') {
            newConfig = new ApivValidationConfig(JSON.parse(configJson));
          } else {
            newConfig = new ApivValidationConfig(configJson);
          }

          // check if we have to modify the name of the config because it is the same as one of the loaded configs
          if (this.configs) {
            for (const config of this.configs) {
              if (config.name === newConfig.name) {
                newConfig.name = 'LOCAL-' + newConfig.name;
                break;
              }
            }
          }

          localStorage.removeItem(VALIDATION_CONFIG_LOCAL_ZIP);
          this.persistedConfigZipFileConfigName = '';
          this.persistedConfigZipFile = null;

          localStorage.setItem(VALIDATION_CONFIG_LOCAL_JSON, JSON.stringify(newConfig));
          localStorage.setItem(VALIDATION_CONFIG_NAME, newConfig.name);
          this.configurationName = newConfig.name;
          this.persistedLocalConfig = newConfig;

          this.recreateItems();
        };
        fileReader.readAsText(inputFile, 'UTF-8');
      }
    }
  }

}
