<clr-main-container>

  <div *ngIf="showProductionWarning && !isProduction" class="alert alert-app-level alert-warning" style="margin-bottom:24px" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <div class="alert-text">
          This is NOT a production instance of API Validator! Your mileage may vary.
        </div>
        <div class="alert-actions">
          <button class="btn alert-action" (click)="gotoProduction()">Open Production</button>
        </div>
      </div>
    </div>
    <button (click)="hideProductionWarning()" type="button" class="close" aria-label="Close">
      <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>

  </div>

  <vmw-apiv-header></vmw-apiv-header>

  <div class="content-container">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
