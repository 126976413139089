<h3 style="margin-top: 0px!important;">Validation Request</h3>

<div *ngIf="!validationJob">
  <span *ngIf="pendingRequest" class="spinner spinner-inline"></span>
  <span *ngIf="!pendingRequest && !jobError "><i>Select an OpenAPI spec to validate.</i></span>
</div>

<div *ngIf="validationJob">
  <clr-datagrid>
    <clr-dg-column class="job-id-column">Job ID</clr-dg-column>
    <clr-dg-column class="job-tracking-token-column">Client Tracking Token</clr-dg-column>
    <clr-dg-column class="job-spec-column">OpenAPI Spec</clr-dg-column>
    <clr-dg-column class="job-configuration-column">Configuration</clr-dg-column>
    <clr-dg-column class="job-log-column">Log</clr-dg-column>
    <clr-dg-column class="job-state-column">State</clr-dg-column>
    <clr-dg-column class="job-duplicate-of-column">Duplicate Of</clr-dg-column>
    <clr-dg-column class="job-created-by-column">Created By</clr-dg-column>
    <clr-dg-column class="job-created-time-column">Created Time</clr-dg-column>
    <clr-dg-column class="job-elapsed-column">Elapsed Seconds</clr-dg-column>
    <clr-dg-column class="job-deleted-after-column">Will be deleted after</clr-dg-column>

    <clr-dg-row>
      <clr-dg-cell><a href="{{jobResultsUrl}}">{{validationJob.id}}</a></clr-dg-cell>
      <clr-dg-cell>{{validationJob.client_tracking_token}}</clr-dg-cell>
      <clr-dg-cell><a href="{{jobSpecUrl}}" target="_blank">{{validationJob.api_name}}</a></clr-dg-cell>
      <clr-dg-cell><a href="{{jobConfigUrl}}" target="_blank">Configuration</a></clr-dg-cell>
      <clr-dg-cell><a href="{{jobLogUrl}}" target="_blank">Log</a></clr-dg-cell>
      <clr-dg-cell>{{validationJob.state}}</clr-dg-cell>
      <clr-dg-cell><div [innerHTML]="getDuplicateOfHtml(validationJob)"></div></clr-dg-cell>
      <clr-dg-cell>{{validationJob.created_by}}</clr-dg-cell>
      <clr-dg-cell>{{validationJob.created_time | date:'MMM d, y, h:mm:ss.SSS a'}}</clr-dg-cell>
      <clr-dg-cell>{{ toDurationSeconds(validationJob.created_time,validationJob.finished_time) }}</clr-dg-cell>
      <clr-dg-cell>{{validationJob.expiration_time | date:'medium'}}</clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>
</div>

<!-- TODO better formatting -->
<h3>Validation Results</h3>

<div>
  <div *ngIf="!analysis">
    <span *ngIf="pendingResults" class="spinner spinner-inline"></span>
  </div>

  <div *ngIf="validationJob && validationJob.job_error">
    <div class="alert alert-danger">
      <div class="alert-items">
        <div class="alert-item static" role="alert">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">There was an error while running this validation job.</span>
        </div>
      </div>
    </div>
    <br><span><b>Job Log with error details:</b></span><br>
    <ngx-monaco-editor class="apiv-file-display" style="height: 500px" [options]="errorEditorOptions" [(ngModel)]="validationJob.job_error"></ngx-monaco-editor>
  </div>
  <div *ngIf="resultsErrorCode ==410">
    <div class="alert alert-warning">
      <div class="alert-items">
        <div class="alert-item static" role="alert">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">This validation has expired, no data is available.</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(resultsErrorCode !=410)&&(resultsErrorCode > 399)">
    <div class="alert alert-error">
      <div class="alert-items">
        <div class="alert-item static" role="alert">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">There was an error fetching validation results.</span>
        </div>
      </div>
    </div>
    <br><span><b>Error details:</b></span><br><span>{{resultsErrorMsg}}</span>
  </div>

  <div *ngIf="analysis && resultsJunitUrl">
    <button (click)="downloadHtmlReport()" class="btn btn-link">Single file HTML results</button>
    <button (click)="downloadJunitXmlReport()" class="btn btn-link">Junit XML results</button>
  </div>

  <div *ngIf="analysis && analysis.statistics">
    <clr-datagrid>
      <clr-dg-column class="stat-type">Statistics Type</clr-dg-column>
      <clr-dg-column class="stat-total">Total Count</clr-dg-column>
      <clr-dg-column class="stat-errors">Error Count</clr-dg-column>
      <clr-dg-column class="stat-errors"># With Errors</clr-dg-column>
      <clr-dg-column class="stat-warnings">Warning Count</clr-dg-column>
      <clr-dg-column class="stat-warnings"># With Warnings</clr-dg-column>
      <clr-dg-column class="stat-infos">Info Count</clr-dg-column>
      <clr-dg-column class="stat-infos"># With Infos</clr-dg-column>
      <clr-dg-row *clrDgItems="let stat of analysis.statistics"
                  [clrDgItem]="stat">
        <clr-dg-cell>{{stat.name}}</clr-dg-cell>
        <clr-dg-cell>{{stat.total_count}}</clr-dg-cell>
        <clr-dg-cell>{{stat.error_count}}</clr-dg-cell>
        <clr-dg-cell>{{stat.with_errors + ' (' + ((stat.with_errors * 100)/(stat.total_count==0?1:stat.total_count)).toFixed(0)+ '%)'}}</clr-dg-cell>
        <clr-dg-cell>{{stat.warning_count}}</clr-dg-cell>
        <clr-dg-cell>{{stat.with_warnings + ' (' + ((stat.with_warnings * 100)/(stat.total_count==0?1:stat.total_count)).toFixed(0)+ '%)'}}</clr-dg-cell>
        <clr-dg-cell>{{stat.info_count}}</clr-dg-cell>
        <clr-dg-cell>{{stat.with_infos + ' (' + ((stat.with_infos * 100)/(stat.total_count==0?1:stat.total_count)).toFixed(0)+ '%)'}}</clr-dg-cell>

      </clr-dg-row>
    </clr-datagrid>
    <br>
  </div>

  <div>
    <img *ngIf="showRickRoll && validationJob && (validationJob.result_error_count != null) && (validationJob.result_error_count == 0) && (validationJob.result_warning_count == 0) && (validationJob.result_info_count == 0)"
         src="../../../images/rickroll.gif">
  </div>

  <vmw-accordion-group *ngIf="analysis && analysis.data">

    <vmw-accordion *ngFor="let group of analysis.data" class="no-subheader">
      <!-- TODO fix the padding between the icon and the path -->
      <span class="title">
        <clr-icon *ngIf="group.severity === 'SUCCESS'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
        <clr-icon *ngIf="group.severity === 'INFO'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
        <clr-icon *ngIf="group.severity === 'WARNING'" shape="warning-standard" id="status-icon" class="is-solid"></clr-icon>
        <clr-icon *ngIf="group.severity === 'ERROR'" shape="error-standard" id="status-icon" class="is-solid"></clr-icon>
        {{group.path}}
      </span>
      <div class="accordion-body">
 <!--
        <clr-datagrid>
          <clr-dg-column class="report-type">Type</clr-dg-column>
          <clr-dg-column class="report-method">Method</clr-dg-column>
          <clr-dg-column class="report-message">Message</clr-dg-column>
          <clr-dg-column class="report-file">File:Line</clr-dg-column>
          <clr-dg-column class="report-fields">Field(s)</clr-dg-column>
          <clr-dg-row *clrDgItems="let entry of group.entries"
                      [clrDgItem]="entry">
            <clr-dg-cell>
              <clr-icon *ngIf="entry.severity === 'SUCCESS'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
              <clr-icon *ngIf="entry.severity === 'INFO'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
              <clr-icon *ngIf="entry.severity === 'WARNING'" shape="warning-standard" id="status-icon" class="is-solid"></clr-icon>
              <clr-icon *ngIf="entry.severity === 'ERROR'" shape="error-standard" id="status-icon" class="is-solid"></clr-icon>
            </clr-dg-cell>
            <clr-dg-cell>{{entry.method}}</clr-dg-cell>
            <clr-dg-cell>{{entry.message}}</clr-dg-cell>
            <clr-dg-cell><a href="{{getSourceLineUrl(entry.source_file, entry.source_line)}}" target="_blank">{{entry.source_file}}:{{entry.source_line}}</a></clr-dg-cell>
            <clr-dg-cell>{{entry.fields}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
        -->
        <table class="table table-compact" style="margin-top: 0">
          <thead>
          <tr>
            <th class="left report-type">Type</th>
            <th class="left report-method">Method</th>
            <th class="left report-message">Message</th>
            <th class="left report-file">File:Line</th>
            <th class="left report-fields">Field(s)</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entry of group.entries">
              <td class="left">
                <clr-icon *ngIf="entry.severity === 'SUCCESS'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
                <clr-icon *ngIf="entry.severity === 'INFO'" shape="success-standard" id="status-icon" class="is-solid"></clr-icon>
                <clr-icon *ngIf="entry.severity === 'WARNING'" shape="warning-standard" id="status-icon" class="is-solid"></clr-icon>
                <clr-icon *ngIf="entry.severity === 'ERROR'" shape="error-standard" id="status-icon" class="is-solid"></clr-icon>
              </td>
              <td class="left">{{entry.method}}</td>
              <td class="left"><div [innerHTML]="getEntryMessageHtml(entry)"></div></td>
              <td class="left"><a href="{{getSourceLineUrl(entry.source_file, entry.source_line)}}" target="_blank">{{entry.source_file}}:{{entry.source_line}}</a></td>
              <td class="left">{{entry.fields}}</td>
            </tr>
          </tbody>
        </table>

      </div>

    </vmw-accordion>
  </vmw-accordion-group>
</div>

