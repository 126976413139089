import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import {VmwClarityTheme, VmwClarityThemeConfig, VmwClarityThemeService} from '@vmw/ngx-utils';
import {CLIENT_ID} from '../../app.config';
import {AppConfigService} from '../../services/app-config.service';

// import {Esp} from '@vmw/esp-feedback';
@Component({
  selector: 'vmw-apiv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public VmwClarityTheme = VmwClarityTheme;
  private config = new VmwClarityThemeConfig();

  public FEEDBACK_CLIENT_ID = CLIENT_ID;
  public FEEDBACK_URL = 'https://feedback.esp-staging.vmware-aws.com';
  public openFeedbackForm = false;
  public isProduction: boolean;

  user: any;

  constructor(private router: Router, private userService: UserService,  public themeService: VmwClarityThemeService, private configService: AppConfigService) {
    this.config.clarityDarkPath = './assets/css/clr-ui-dark.min.css';
    this.config.clarityLightPath = './assets/css/clr-ui.min.css';
    this.themeService.initialize(this.config);
    this.FEEDBACK_URL = this.configService.config.feedbackUrl;
    this.isProduction = 'true' === this.configService.config.isProduction;
  }

  ngOnInit() {
    this.userService.getCurrentSession().subscribe(session => {
      console.log('HeaderComponent session change', session);
      if (session) {
        this.user = session.user;
      } else {
        this.user = null;
      }
    });
  }

  themeChanged(newTheme: VmwClarityTheme) {
    this.themeService.theme = newTheme;
  }

  logout() {
    this.userService.logout();
  }

  login() {
    this.userService.redirectToLogin();
  }

}
