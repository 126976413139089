/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 */
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';


import { map, flatMap, catchError, debounceTime, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  VmwAPIXService, APIXApiDef, APIXApi,
} from "@vmw/ngx-dev-center";
import {distinctUntilChanged} from 'rxjs/internal/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from '../../services/app-config.service';

@Component({
  selector: 'vmw-apiv-choose-api',
  templateUrl: './choose-api.component.html',
  styles: [`
  .filter-apis {
    text-align: right;
  }
`],
})
export class ChooseApiComponent implements OnInit, OnChanges {

  @Output()
  onSelectApi = new EventEmitter<APIXApi>();

  @Input() disabled: boolean;

  apidefs: APIXApiDef[];
  apis: APIXApi[];
  filteredApis: APIXApi[];
  selectedApi: APIXApi;
  filterSubject: Subject<string> = new Subject<string>();
  loading: boolean;

  constructor(private apixService: VmwAPIXService,
              private http: HttpClient,
              private environment: AppConfigService) { }

  ngOnInit() {
    console.log("ChooseApiComponent.ngOnInit()");
    this.loadData();
  }

  ngOnChanges() {
      console.log("ChooseApiComponent.ngOnChanges()");
      this.loadData();
  }

  loadData() {
    console.log("ChooseApiComponent.loadData()");
      this.getApis();
  }

  getApis() {
    console.log("ChooseApiComponent.getApis()");
    this.loading = true;
    //this.apixService.getRemoteApis()

    const url = this.environment.config.codeDcrUrl + '/apix/apis/defs';
    this.http.get(url)
      .subscribe( (apidefs: Array<APIXApiDef>) => {
        // console.log("got apidefs",apidefs);
        this.loading = false;
        this.apidefs = apidefs;

        this.apis = Array<APIXApi>();
        for (let apidef of apidefs) {
          // the first API release, if the def has releases, is the most recent release, just use it
          if (apidef.api_releases && apidef.api_releases.length > 0) {
            let api: APIXApi = apidef.api_releases[0];
            if ('SWAGGER' === api.api_ref_doc_type) {
              this.apis.push(api);
            }
          }
        }

        // in the beginning no api is filtered
        this.filteredApis = this.apis;

        this.filterSubject.pipe( distinctUntilChanged(),
                                 debounceTime(500)).subscribe(apiName => {
          apiName = apiName.toLowerCase();
          this.filteredApis = this.apis.filter(api => {
            return api.name.toLowerCase().includes(apiName);
          });
        });


        // implementation to handle the artifact filtering
        //this.filterSubject.debounceTime(500).distinctUntilChanged().subscribe(apiName => {
        //  apiName = apiName.toLowerCase();
        //  this.filteredApis = this.apis.filter(api => {
        //    return api.name.toLowerCase().includes(apiName);
        //  });
        //});
      });
  }

  /* Get APIs from the code.vmware.com  */
//  public getApis() {
//    return this.apixService.Apix.Apis.getApis({} as GetApisQueryParams )
//      .pipe(
//        map((apis: Array<APIXApi>) => {
//          return apis.filter(api => api.api_ref_doc_type === 'SWAGGER');
//        }), catchError((error: any) => {
//          return error;
//        })
//      );
//  }

  /**
   * This method captures which artifact is selected, and emit the event to the parent
   * @param event
   */
  selectApi(event) {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      console.log("ChooseApiComponent.selectApi(id=" + id + ")",new Error().stack);
      const selectedApi: APIXApi = this.apis.find( api => id == api.id);
      console.log("emitting event onSelectApi api=",selectedApi);
      this.onSelectApi.emit(selectedApi);
  }

  onTypingFilter(event) {
    // emitt and event with the filter string
    this.filterSubject.next(event.target.value);
  }
}
