import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, catchError, mergeMap, flatMap, delay, retry } from 'rxjs/operators';
import { throwError, of, interval } from 'rxjs';
import { AppConfigService } from './app-config.service';
import {ApivValidationRequest, ApivValidationResults} from '../../apiv-client/Apiv/types';
import {ApivValidationJob, ApivValidationJobs} from '../../apiv-client/Apiv/types';
import {ApivValidationConfigs} from '../../apiv-client/Apiv/types/ApivValidationConfigs';
import {ApivServiceInfo} from '../../apiv-client/Apiv/types/ApivServiceInfo';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  apivApiUrl: string = '';
  apivApiHostUrl: string = '';
  apivApiBasePath: string = '';
  buildId: string = '';

  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.apivApiUrl = environment.config.apivApiUrl;
    const apivUrl: URL = new URL(this.apivApiUrl);
    this.apivApiHostUrl = apivUrl.protocol + '//' + apivUrl.hostname + (apivUrl.port ? ':' + apivUrl.port : '');
    this.apivApiBasePath = apivUrl.pathname;
    this.buildId = environment.config.buildId;
    console.log('App service startup of buildId=' + this.buildId + ' using apivApiUrl=' + this.apivApiUrl);
  }

  getAnalysisServiceHostUrl() {
    return this.apivApiHostUrl;
  }

  getAnalysisServiceBasePath() {
    return this.apivApiBasePath;
  }

  getAnalysisServiceBaseUrl() {
    return this.apivApiUrl;
  }

  getAnalysisServiceSwaggerUrl() {
    return this.apivApiUrl + '/assets/swagger.json';
  }

  getConfigFileUrl(name: string) {
    return this.apivApiUrl + '/configs/' + name + '/file';
  }

  getFileUrl(job_id: String, fileType: string) {
    return this.apivApiUrl + '/validation-jobs/' + job_id + '/' + fileType;
  }

  getHtmlReportUrl(job_id: string) {
    return this.getFileUrl(job_id, 'html');
  }

  getJunitReportUrl(job_id: string) {
    return this.getFileUrl(job_id, 'junit');
  }

  getSpecUrl(job_id: string, line?: number) {
    return this.getJobResultsViewerUrl(job_id, 'spec', line);
  }

  getConfigUrl(job_id: string) {
    return this.getJobResultsViewerUrl(job_id, 'config');
  }

  getLogUrl(job_id: string) {
    return this.getJobResultsViewerUrl(job_id, 'log');
  }

  getJobResultsUrl(job_id: string) {
    const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    const jobUrl = full + '/validations/' + job_id;
    return jobUrl;
  }

  getJobResultsUrlRelative(job_id: string) {
    //const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    //const jobUrl = full + '/validations/' + job_id;
    return '/validations/' + job_id;
  }

  getDuplicateOfHtml( duplicate_of_id: string): string {   
    if (duplicate_of_id) {
      const url = this.getJobResultsUrl(duplicate_of_id);
      if (url) {
        const returnHtml = '<a href="' + url + '" target="_blank">' + duplicate_of_id + '</a>';
        return returnHtml;
      }
    }
    return '';
  }

  getJobResultsViewerUrl(job_id: string, type: string, line?: number) {
    const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    const jobUrl = full + '/validations/' + job_id + '/' + type + (line ? ('?line=' + line) : '');
    return jobUrl;
  }

  getAnalysisServiceInfo(): Observable<ApivServiceInfo> {
    return this.http.get(this.apivApiUrl + '/');
  }

  public getHtmlReportBlob(job_id: string): Observable<Blob> {
    return this.http.get(this.getHtmlReportUrl(job_id), {responseType: 'blob'});
  }

  public getJunitReportBlob(job_id: string): Observable<Blob> {
    return this.http.get(this.getJunitReportUrl(job_id), {responseType: 'blob'});
  }

  getSharedConfigs(): Observable<ApivValidationConfigs> {
    return this.http.get(this.apivApiUrl + '/configs');
  }

  getValidationStatus(jobId: string): Observable<ApivValidationJob> {
    return this.http.get(this.apivApiUrl + '/validation-jobs/' + jobId);
  }

  /**
   *
   * @param allUsers if true, all users are queried
   * @param user_filter optional list of specific users to show validations for.  If not provided, it is implied that it is the current user only.
   * @param state_filter optional list of states to display.  one of NEW, RUNNING, ERROR, FINISHED, EXPIRED
   * @param offset
   * @param limit
   */
  getValidationStatuses(allUsers: boolean, user_filter: string, state_filter: string, offset: number, limit: number): Observable<ApivValidationJobs> {
    let url = this.apivApiUrl + '/validation-jobs?offset=' + offset + '&limit=' + limit;
    if (allUsers) {
      url = url + '&all_users=true';
    }
    if (user_filter && user_filter.length > 0) {
      url = url + '&user_filter=' +user_filter
    }
    if (state_filter && state_filter.length > 0) {
      url = url + '&state_filter=' + state_filter;
    }
    return this.http.get<ApivValidationJobs>(url);
  }

  getResults(jobId: string): Observable<ApivValidationResults> {
    return this.http.get(this.apivApiUrl + '/validation-jobs/' + jobId + '/results');
  }

  public postApiValidationJobForFile(formData: FormData): Observable<ApivValidationJob> {
    return this.http.post<ApivValidationJob>(this.apivApiUrl + '/validation-jobs/files', formData);
  }

  public postApiValidationJob(request: ApivValidationRequest): Observable<ApivValidationJob> {
    return this.http.post<ApivValidationJob>(this.apivApiUrl + '/validation-jobs', request);
  }

  /**
   * This method will invoke an observable and use his output id to check the job status in a loop
   * @param observable The observable to be invoked before starting checking process
   */
  postAndCheckStatus(observable): Observable<ApivValidationJob> {
    return Observable.create(observer => {
      observable.flatMap(
        validationJob => {
          return this.getValidationStatus(validationJob.id).pipe(
            delay(1000),
            flatMap(
              jobStatus => {
                console.log("Got job status for id=" + validationJob.id, jobStatus);// FIXME
                if (jobStatus.state === 'FINISHED') {
                  console.log("Got finished status on job" + validationJob.id);// FIXME
                  return of(jobStatus);
                } else {
                  console.log("retrying get status: " + validationJob.id); // FIXME
                  // If status is not finished, so retry JobStatus()
                  return throwError('retry');
                }
              }
            ),
            retry());
        }
      ).subscribe(
        validationJob => {

          console.log("Validation job finished:", validationJob); //FIXME

          // The job has finished, it is necessary to check if there is any error
          if (validationJob.job_error == null) {
            observer.next(validationJob);
          } else {
            const error = {
              job: validationJob,
              error: validationJob.job_error
            }
            observer.error(error);
          }
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      )
    });
  }

  pollForFinishedStatus(jobId: string): Observable<ApivValidationJob> {
    return this.getValidationStatus(jobId)
      .pipe(
        delay(500),
        flatMap(
          jobStatus => {
            if ((jobStatus.state === 'FINISHED') || (jobStatus.state === 'ERROR')) {
              return of(jobStatus);
            } else {
              return throwError('retry');
            }
          }
        ),
        retry()
      );
  }
}
