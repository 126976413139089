
<script src="../../../../node_modules/@vmw/ngx-components/vmw-ngx-components.module.d.ts"></script>
<div class="clr-row clr-align-items-center">

   <div class="clr-col-8">
    <ng-container *ngIf="loading;then isLoading else loadingComplete">
    </ng-container>

    <ng-template #isLoading>
      <div class="vcode-spinner">
          <span class="spinner">
          </span>
      </div>
    </ng-template>

    <ng-template #loadingComplete>

      <div *ngIf="errorMessage">
          <div class="alert alert-danger">
            <div class="alert-items">
              <div class="alert-item static" role="alert">
                <div class="alert-icon-wrapper">
                  <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                </div>
                <span class="alert-text">{{errorMessage}}</span>
              </div>
            </div>
          </div>
      </div>
      <div *ngIf="!errorMessage">
        <vmw-combobox
          [items]="items"
          [autoSelectOnPrefix]="false"
          [isAddNewAllowed]="false"
          [autoComplete]="false"
          [filterIncludes]="false"
          [filterItemsWhenTyping]="true"
          [showTooltip]="false"
          [(ngModel)]="selectedItem"
          (ngModelChange)="onItemSelected()"
          placeholder="Choose a configuration">

        </vmw-combobox>
      </div>
    </ng-template>
  </div>
  <!--
  <ng-template let-item>
            {{item.name}} : {{item.description}}
          </ng-template>
  -->
  <div class="clr-col-4">
    <input type="file" id="selectedConfigFile" style="display: none;" accept=".json,.zip" (change)="onConfigFileChange($event)"/>
    <input type="button" class="btn btn-link" value="Load Local Configuration File" onclick="document.getElementById('selectedConfigFile').click();" />
  </div>
</div>
