import {ApivValidationConfig} from '../../apiv-client/Apiv/types';

export class ApivConfigWrapper {
  public configZipFile?: File;
  public apivConfig?: ApivValidationConfig;
  public name?: string;
  constructor(json: any) {
    if (json) {
      Object.assign(this, json);
    } else {
      this.configZipFile = null;
      this.apivConfig = null;
      this.name = null;
    }
  }
}
