import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { CLIENT_ID, REDIRECT_URI, STATE } from '../../app.config';
import { Location } from '@angular/common';

@Component({
  selector: 'vmw-apiv-login',
  template: ``
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,
    private userService: UserService, private authService: AuthService, private location: Location) {
    console.log('LoginComponent constructor');
  }

  ngOnInit() {
    console.log('LoginComponent ngOnInit');
    this.route.queryParams.pipe(
      map(params => params),
      mergeMap((data:any) => {
        const code = data['code'];
        const state = data['state'];

        const decodedState = atob(state);
        const stateObject = JSON.parse(decodedState);

        // TODO AARON: this usage of rxjs is totally and completely confusing to me.  both of these blocks are executed in the same
        // sequence, that is when logging in and getting called back by the auth service and yet I cannot figure out how to simply access
        // the query params in the other block so am doing this hack with putting in local storage which is stupid.

        localStorage.setItem('returnUrl',stateObject.returnUrl);

        // console.log('got state object on return',stateObject);

        const redirectUri = window.location.origin + this.location.prepareExternalUrl(REDIRECT_URI);
        return this.authService.authenticate(code, CLIENT_ID, redirectUri);
    })).subscribe(
      (response: any)=> {
        // console.log('got auth response ',response);
        this.userService.saveCurrentSession(response);
        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl) {
          this.router.navigate([returnUrl]);
        } else {
          this.router.navigate(['/']);
        }
      },
      (error) => {
        console.log(error);
        this.userService.logout();
      }
    );
  }
}
