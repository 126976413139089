// tslint:disable


export class ApivConfigScript {
    public path?: string;
    public encoding?: string;
    public body?: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
