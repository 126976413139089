<form name="createValidationForm" clrForm #f="ngForm">

  <vmw-apiv-choose-config (onSelectConfig)="onSelectConfig($event);"></vmw-apiv-choose-config>

  <!-- this usage of ngIf=selectedConfig is to support disabling starting a validation if there is no config selected.
  there is probably a better way, but I could not figure it out. -->
  <h3>Select an OpenAPI specification to validate</h3>
  <clr-radio-container *ngIf="selectedConfig">
    <clr-radio-wrapper>
      <input type="radio" clrRadio name="uploadMethod" required value="useLocalFile" [(ngModel)]="uploadMethod" />
      <label>Local file</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input type="radio" clrRadio name="uploadMethod" required value="useManualUrl" [(ngModel)]="uploadMethod" />
      <label>URL of OpenAPI/Swagger spec</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input type="radio" clrRadio name="uploadMethod" required value="useCode" [(ngModel)]="uploadMethod" />
      <label>Published OpenAPI spec from code.vmware.com</label>
    </clr-radio-wrapper>
    <clr-control-error>This field is required!</clr-control-error>
  </clr-radio-container>
  <clr-radio-container *ngIf="!selectedConfig">
    <clr-radio-wrapper>
      <input type="radio" clrRadio name="uploadMethod" required value="useLocalFile" [(ngModel)]="uploadMethod"/>
      <label>Local file</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>

      <input type="radio" clrRadio name="uploadMethod" required value="useManualUrl" [(ngModel)]="uploadMethod" />
      <label>URL of OpenAPI/Swagger spec</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input type="radio" clrRadio name="uploadMethod" required value="useCode" [(ngModel)]="uploadMethod" disabled />
      <label>Published OpenAPI spec from code.vmware.com</label>
    </clr-radio-wrapper>
    <clr-control-error>This field is required!</clr-control-error>
  </clr-radio-container>


  <div class="card" *ngIf="uploadMethod === 'useLocalFile'">
    <div class="card-header">OpenAPI/Swagger file upload (JSON or YAML format, or a zip file of a spec and its referenced dependencies)</div>
    <div class="card-block">
      <input id="localFile" style="width:100%" type="file" accept=".json,.yaml,.yml,.zip" [disabled]="!selectedConfig" (change)="onLocalFileChange($event)" />
    </div>

    <div *ngIf="zipFileEntries && zipFileEntries.length > 0">
      <p style="color: red;margin-top: 0px;"> *You need to select an OpenAPI specification inside of your zip</p>
      <div class="file-table">
        <app-file-table #table
            [files]="zipFileEntries"
            (selectTableFile)="selectTableFile($event)"></app-file-table>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="uploadMethod === 'useManualUrl'">
    <div class="card-header">OpenAPI/Swagger file URL (note: The server must have CORS support enabled. The spec is downloaded in your browser...)</div>
    <div class="card-block">
      <div class="clr-row">
        <div class="clr-col-11">

          <vmw-combobox name="selectedUrl"
            [items]="urlItems"
            [autoSelectOnPrefix]="false"
            [isAddNewAllowed]="true"
            [autoComplete]="false"
            [filterIncludes]="false"
            [filterItemsWhenTyping]="false"
            [showTooltip]="false"
            [(ngModel)]="selectedUrlItem"
            (ngModelChange)="onItemSelected()"
            placeholder="Enter or choose a previous OpenAPI spec URL">
          </vmw-combobox>
          <!--
          <input id="swagger-url" placeholder="URL for your OpenAPI spec here" name="swaggerURL" [(ngModel)]="swaggerURL" />
          -->
        </div>
        <div class="clr-col-1">
          <button (click)="validateFromUrl()" [disabled]="!swaggerURL || !selectedConfig" class="btn btn-primary">Validate</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="uploadMethod === 'useCode'">
    <div class="card-header">Select API from code.vmware.com</div>
    <div class="card-block">
      <vmw-apiv-choose-api (onSelectApi)="onSelectApi($event);" [disabled]="!selectedConfig"></vmw-apiv-choose-api>
    </div>
  </div>

  <div *ngIf="statusMessage || errorMessage" class="card">
    <div class="card-block">
      <div *ngIf="statusMessage && !errorMessage" class="clr-row">
        <div class="clr-col-12">
          <span><b>{{statusMessage}}</b></span>
        </div>
      </div>
      <div *ngIf="statusMessage && !errorMessage" class="clr-row">
        <div class="clr-col-12">
          <span class="spinner spinner-inline"></span>
        </div>
      </div>
      <div *ngIf="statusMessage && errorMessage" class="clr-row">
        <div class="clr-col-12">
          <div class="alert alert-danger">
            <div class="alert-items">
              <div class="alert-item static" role="alert">
                <div class="alert-icon-wrapper">
                  <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                </div>
                <span class="alert-text">{{errorMessage}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
