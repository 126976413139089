/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {ApivValidationJob} from '../../../apiv-client/Apiv/types';
import {AnalysisService} from '../../services/analysis.service';

@Component({
  templateUrl: './new-validation-page.component.html',
  styleUrls: ['./new-validation-page.component.css'],
})

export class NewValidationPageComponent {
  jobId: string = null;

  lintOpenAPIVMwareStandardConfigUrl: string;
  spectralVMwareStandardConfigUrl: string;
  defaultConfigUrl: string;

  constructor(private router: Router, private analysisService: AnalysisService) {
    this.lintOpenAPIVMwareStandardConfigUrl = analysisService.getConfigFileUrl('VMware-Standard-lint-openapi');
    this.spectralVMwareStandardConfigUrl = analysisService.getConfigFileUrl('VMware-Standard-Spectral');
    this.defaultConfigUrl = analysisService.getConfigFileUrl('default');
  }

  /**
   * called by the create-validation component when there is a new validation to display.
   * @param {ApivValidationJob} validationJob
   */
  onDisplayValidation(validationJob: ApivValidationJob) {
    console.log("NewValidationPageComponent onDisplayValidation",validationJob.id);
    this.jobId = validationJob.id;
    this.router.navigateByUrl('/validations/' + this.jobId);
  }
}
