/**
 * Copyright 2018-2020 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */
import { Injectable } from '@angular/core';
import * as spectralRequirementArray from '../../assets/spectralRequirementMapping.json';

const reqMappingArray: any[] = (spectralRequirementArray as any).default;

@Injectable({
  providedIn: 'root'
})
/**
 * This service provides access to information about standards coverage.
 */
export class StandardService {
  private nameToObjectMap = new Map<string, any>();
  constructor () {
    for (const entry of reqMappingArray ) {
      if (entry.validation) {
        this.nameToObjectMap.set(entry.validation, entry);
      }
    }
  }

  /**
   * Get the standard requirements.  each object has id, summary
   */
  getStandardRequirements(): any[] {
    return reqMappingArray;
  }

  getRequirementUrlForId(reqId: string): string {
    return 'https://confluence.eng.vmware.com/pages/viewpage.action?spaceKey=Standards&title=REST#' + reqId;
  }

  /**
   * Get the URL that explains the given validation name
   * @param validation_name
   */
  getValidationUrl(validation_name: string): string {
    if (! validation_name) {
      return null;
    }
    return 'https://stoplight.io/p/docs/gh/stoplightio/spectral/docs/reference/openapi-rules.md#' + validation_name;
  }

  /**
   * for the given validation_name, get a URL to the requirement if there is one.  If there is not, null is returned.
   * @param validation_name
   */
  getRequirementUrl(validation_name: string): string {
    if (validation_name) {
      const mappingEntry: any = this.nameToObjectMap.get(validation_name);
      if (mappingEntry && mappingEntry.id) {
        return this.getRequirementUrlForId(mappingEntry.id);
      }
    }
    return null;
  }

}
