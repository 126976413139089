import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from "@angular/router";
// ClrCheckboxNextModule
import { ClarityModule, ClrFormsModule, ClrControlHelper, ClrCommonFormsModule, ClrInputModule, ClrCheckboxModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from './services/app-config.service';
import { VmwClarityThemeService } from "@vmw/ngx-utils";
import { VmwComponentsModule } from "@vmw/ngx-components";
import { VmwDevCenterModule } from "@vmw/ngx-dev-center";

import { CurlDataParserService } from '@vmw/ngx-dev-center';
import { VmwAPIXService } from '@vmw/ngx-dev-center';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { JobDisplayComponent } from './components/job-display/job-display.component';
import { JobDisplayPageComponent } from './components/job-display-page/job-display-page.component';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { ConfigDisplayComponent } from './components/config-display/config-display.component';
import { HeaderComponent } from './components/header/header.component';
import { ApivDevCenterComponent} from './components/apiv-dev-center/apiv-dev-center.component';
import { ROUTING } from './app.routing';
import { ChooseApiComponent } from './components/choose-api/choose-api.component';
import { ChooseConfigComponent } from './components/choose-config/choose-config.component';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth.guard';
import { AuthInterceptor } from './services/auth.interceptor';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import {CreateValidationComponent} from './components/create-validation/create-validation.component';
import {FileTableComponent} from './components/create-validation/file-table/file-table.component';
import {NewValidationPageComponent} from './components/new-validation-page/new-validation-page.component';
import {VmwApivAppInfoComponent} from './components/app-info.component';
import {MyValidationsComponent} from './components/my-validations/my-validations.component';
import {StandardDisplayComponent} from './components/standard-display/standard-display.component';

// must load the config.json file at app intialization time
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    JobDisplayComponent,
    JobDisplayPageComponent,
    FileDisplayComponent,
    ConfigDisplayComponent,
    CreateValidationComponent,
    FileTableComponent,
    HeaderComponent,
    ChooseApiComponent,
    ChooseConfigComponent,
    LoginComponent,
    NewValidationPageComponent,
    VmwApivAppInfoComponent,
    MyValidationsComponent,
    StandardDisplayComponent
  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    ClrCommonFormsModule,
    ClrInputModule,
    HttpClientModule,
    ROUTING,
    VmwDevCenterModule.forRoot(),
    VmwComponentsModule.forRoot(),
    MonacoEditorModule.forRoot()
  ],
  providers: [
    AppConfigService,
    VmwClarityThemeService,
    VmwAPIXService,
    AuthService, UserService, AuthGuard,
    CurlDataParserService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
