
/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChooseApiComponent} from '../choose-api/choose-api.component';
import { ChooseConfigComponent} from '../choose-config/choose-config.component';
import { AnalysisService } from '../../services/analysis.service';
import {VmwComboboxItem} from '@vmw/ngx-components';
import * as JSZip from 'jszip';

import { JobDisplayComponent} from '../job-display/job-display.component';

import {
  VmwAPIXService, APIXApi,
} from "@vmw/ngx-dev-center";
import {ApivValidationRequest} from '../../../apiv-client/Apiv/types';
import {ApivValidationJob} from '../../../apiv-client/Apiv/types';
import {ApivValidationConfig} from '../../../apiv-client/Apiv/types';
import {Observable} from 'rxjs';
import {UserService} from '../../services/user.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApivConfigWrapper} from '../../models/ApivConfigWrapper';
import * as CryptoJS from 'crypto-js';
import {ZipFileEntry} from './file-table/file-table.component';

const APIV_LAST_USED_URL = 'APIV_LAST_USED_URL';
const APIV_LAST_USED_METHOD = 'APIV_LAST_USED_METHOD';
const APIV_URLS = 'APIV_URLS';

@Component({
  selector: 'vmw-apiv-create-validation',
  templateUrl: './create-validation.component.html',
  styleUrls: ['./create-validation.component.css'],})

export class CreateValidationComponent implements OnInit {

  selectedUrlItem: VmwComboboxItem = null;
  urlItems: Array<VmwComboboxItem> = [];

  uploadMethod: string = '';
  selectedConfig: ApivConfigWrapper = null;
  swaggerURL: string;
  zipInputFile: File = null;
  zipFileEntries: Array<string> = null;

  pendingRequest: boolean = false;
  validationJob: ApivValidationJob;

  statusMessage: string;
  errorMessage: string;

  @Output()
  displayValidation = new EventEmitter<ApivValidationJob>();

  constructor(private http: HttpClient, private analysisService: AnalysisService, private apixService: VmwAPIXService, private userService: UserService) {
    this.swaggerURL = localStorage.getItem(APIV_LAST_USED_URL); // may be null
    this.uploadMethod = localStorage.getItem(APIV_LAST_USED_METHOD); // may be null

    // the selection is built in with the useCode method, so switch it
    if ('useCode' === this.uploadMethod) {
      this.uploadMethod = 'useManualUrl';
    }

    this.loadUrls();
  }

  private clearJobVars(): void {
    this.validationJob = null;
    this.pendingRequest = false;
    this.statusMessage = null;
    this.errorMessage = null;
    this.zipInputFile = null;
    this.zipFileEntries = null;
  }

  private loadUrls() {

   let urlArray = [];
    const itemArray = new Array<VmwComboboxItem>();

    try {
      const stringifiedArray = localStorage.getItem(APIV_URLS);
      if (stringifiedArray) {
        urlArray = JSON.parse(stringifiedArray);
      }
    } catch (e) {
    }

    if (urlArray) {
      let foundSwagger = false;
      for (const url of urlArray) {
        // console.log("got url", url);
        if (url) {
          if (this.swaggerURL && (url === this.swaggerURL)) {
            foundSwagger = true;
          }
          const urlItem = new VmwComboboxItem(url, url);
          itemArray.push(urlItem);
        }
      }
      if (!foundSwagger && this.swaggerURL) {
        itemArray.push(new VmwComboboxItem(this.swaggerURL, this.swaggerURL));
      }

      this.urlItems = itemArray;

      if (this.urlItems.length > 0) {
        this.selectedUrlItem = this.urlItems[0];
        // console.log("setting selected item to",this.selectedUrlItem);
      } else {
        this.selectedUrlItem = new VmwComboboxItem(' ', ' ');
        this.urlItems.push(this.selectedUrlItem);
      }
    } else {
      this.urlItems = new Array<VmwComboboxItem>();
      this.selectedUrlItem = new VmwComboboxItem('', '');
      this.urlItems.push(this.selectedUrlItem);
    }
  }

  private storeUrls() {

    let urlArray = [];
    if (this.swaggerURL) {
      urlArray.push(this.swaggerURL);
    }
    // TODO max limit
    for (const item of this.urlItems) {
      // console.log('storing',item);
      if (item.value !== this.swaggerURL) {
        urlArray.push(item.value);
      }
    }
    const stringifiedArray = JSON.stringify(urlArray);
    // console.log("CreateValidationComponent storing URL array",stringifiedArray);
    localStorage.setItem(APIV_URLS,stringifiedArray);
  }

  ngOnInit() {
  }

  private downloadUrl( url: string): Observable<Blob> {
    return this.http.get(url, { headers: new HttpHeaders(), responseType: 'blob' });
  }

  validateFromUrl() {
    console.log('CreateValidationComponent.validateFromUrl', this.swaggerURL );

    this.clearJobVars();

    if (this.selectedUrlItem) {
      this.swaggerURL = this.selectedUrlItem.value;
    }

    if (! this.swaggerURL) {
      return;
    }

    this.pendingRequest = true;

    const url: URL = new URL(this.swaggerURL);

    // split off any path info
    let fileName = this.swaggerURL.substring(this.swaggerURL.lastIndexOf('/')+1);
    // remove any tokens or other query args.
    if (fileName.includes('?')) {
      fileName = fileName.substring(0,fileName.indexOf('?'));
    }
    // /tmp/validation-job-APIV-UI_192-2282630663086489082/index.html?docToken=ZXhwaXJlcz0xNTc4MTAwOTUzfmFjY2Vzcz0vdm13Yi1yZXBvc2l0b3J5L2Rjci9mZDViYTViOS1lNzBhLTRkMDQtYmU5MC1mMWZlZmQ1MTRiNzMvOWVlMzQ3YTEtNTIzNC00NWYzLThjZmQtMGQ4MWQyYzdmNjhlL2RvYy8qfm1kNT1jODc0MzRkMDBlNmNkNzExYTNiZTU3MmFhYTg3NGZkOQ&vdcDownload=1578100953_f540356ae3bbb3b0de08b1e5cba7340f: File name too long
    const fileExtension = fileName.split('.').pop();

    this.statusMessage = 'Downloading ' + this.swaggerURL;

    console.log('CreateValidationComponent Downloading file=',fileName,fileExtension,'from',this.swaggerURL);
    this.downloadUrl(this.swaggerURL).subscribe( file => {

      localStorage.setItem(APIV_LAST_USED_URL, this.swaggerURL);
      console.log('Downloaded file size=:', file.size);
      const formData = new FormData();
      formData.append('api_file', file, fileName);
      formData.append( 'user', this.userService.getUser().email);
      formData.append( 'client_tracking_token', ''); // TODO make this a preference
      formData.append( 'job_id_prefix', 'APIV-UI'); // TODO make this a preference

      this.configToFormData(formData);

      this.statusMessage = null;

      this.analysisService.postApiValidationJobForFile(formData).subscribe(
        response => {
          this.validationJob = response as ApivValidationJob;
          this.onValidation();
        },
        error => {
          this.pendingRequest = false;
          this.errorMessage = (typeof error === 'string') ? error : error.error;
          console.log('CreateValidationComponent GOT ERROR: ', this.errorMessage);
        },
        () => {
        }
      );

    }, error => {
      this.pendingRequest = false;
      this.errorMessage = 'While downloading \'' + this.swaggerURL + '\':\n' + ((typeof error === 'string') ? error : error.message);
      console.log('CreateValidationComponent DOWNLOAD ERROR: ', error);
    });
  }

  configToFormData( formData: FormData ): void {
    if (this.selectedConfig) {

      if (this.selectedConfig.apivConfig) {
        const configString = JSON.stringify(this.selectedConfig.apivConfig);
        formData.append( 'config_file', configString);
      } else {
        // const file_wordArr = CryptoJS.lib.WordArray.create(this.selectedConfig.configZipFile);
        // const configZipSha1 = CryptoJS.SHA1(file_wordArr);
        formData.append('config_zip', this.selectedConfig.configZipFile, this.selectedConfig.configZipFile.name);
        // formData.append('config_zip_sha1', configZipSha1);
        console.log('config_zip', this.selectedConfig.configZipFile);//, 'config_zip_sha1', configZipSha1);
      }
    } else {
      formData.append( 'config_file', 'default');
    }
  }

  getSourceLineUrl(file: string, line?: number, column?: number): string {
    return this.analysisService.getSpecUrl(this.validationJob.id, line);
  }

  private onValidation() {
    this.pendingRequest = false;
    console.log('CreateValidationComponent.onValidation: ', this.validationJob);
    localStorage.setItem(APIV_LAST_USED_METHOD, this.uploadMethod);
    this.storeUrls();
    this.displayValidation.emit(this.validationJob);
  }

  onItemSelected() {
    console.log('CreateValidationComponent.onItemSelected',this.selectedUrlItem);
  }

  validateFromFile(inputFile: File) {
    console.log('CreateValidationComponent.validateFromFile');

    this.clearJobVars();
    this.swaggerURL = null;

    if (! inputFile) {
      return;
    }

    this.pendingRequest = true;

    const fileExtension = inputFile.name.split('.').pop();
    console.log('local file selected ', inputFile);

    if ('zip' === fileExtension) {
      this.zipFileEntries = null;

      const zipFileEntries = [];
      const jsZip: JSZip = new JSZip();
      jsZip.loadAsync(inputFile).then(zip => {
        zip.forEach(fileRef => {
          if (!zip.files[fileRef].dir) {
            const fileName: string = zip.files[fileRef].name;
            zipFileEntries.push({
              'name': fileName,
              'location': fileName,
              'provider': null,
            });
          }
        });
        this.zipFileEntries = zipFileEntries;
        this.zipInputFile = inputFile;
      });
    } else {
      // not a zip start validation now.
      this.validateFileInternal( inputFile, null);
    }
  }

  validateFileInternal( inputFile: File, inputSpecPath: string) {
    const formData = new FormData();

    if (! inputSpecPath ) {
      inputSpecPath = inputFile.name;
    }

    formData.append('api_file', inputFile, inputFile.name);
    formData.append('input_spec_path', inputSpecPath);
    formData.append( 'user', this.userService.getUser().email);
    formData.append( 'client_tracking_token', ''); // TODO make this a preference
    formData.append( 'job_id_prefix', 'APIV-UI');// TODO make this a preference

    this.configToFormData(formData);

    console.log('CreateValidationComponent.validateFromFile  calling postApiValidationJobForFile');
    this.analysisService.postApiValidationJobForFile(formData).subscribe(
      response => {
        this.validationJob = response as ApivValidationJob;
        this.onValidation();
      },
      error => {
        this.pendingRequest = false;
        this.errorMessage = (typeof error === 'string') ? error : error.error;
        console.log('GOT ERROR: ', this.errorMessage);
      },
      () => {
      }
    );
  }

  onLocalFileChange(event) {
    console.log('CreateValidationComponent.onLocalFileChange', event );
    if (event.target.files.length > 0) {
      const inputFile = event.target.files[0];
      this.validateFromFile(inputFile);
    }
  }

  selectTableFile(file: ZipFileEntry) {
    const inputSpecPath = this.zipInputFile.name + '!' + file.name;
    console.log('Queueing zip file change', this.zipInputFile, inputSpecPath);
    this.validateFileInternal( this.zipInputFile, inputSpecPath);
  }

  onSelectApi(api) {
    this.swaggerURL = api.api_ref_doc_url;
    this.selectedUrlItem = null;
    console.log('CreateValidationComponent.onSelectApi url=', this.swaggerURL);

    if (!this.pendingRequest) {
      this.validateFromUrl();
    } else {
      console.log('There is already a pending request, skipping queueing job');
    }

  }

  onSelectConfig(config) {
    console.log('CreateValidationComponent.onSelectConfig');
    this.selectedConfig = config;
  }
}
