/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit, ViewChild,  AfterViewInit } from '@angular/core';
import { JobDisplayComponent} from '../job-display/job-display.component';
import {ApivValidationJob} from '../../../apiv-client/Apiv/types';
import {AppConfigService} from '../../services/app-config.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit, OnInit {
  @ViewChild(JobDisplayComponent,{ static: true })
  private jobDisplay: JobDisplayComponent;
  private validationJob: ApivValidationJob;
  private jobId: string;
  private showCreateValidation = false;
  private isProduction: boolean;

  constructor(private appConfigService: AppConfigService) {
    this.isProduction = 'true' === appConfigService.config.isProduction;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  /**
   * called by the create-validation component when there is a new validation to display.
   * @param {ApivValidationJob} validationJob
   */
  onDisplayValidation(validationJob: ApivValidationJob) {
    console.log("HomeComponent onDisplayValidation",validationJob.id);
    this.showCreateValidation = false;
    this.validationJob = validationJob;
    this.jobId = validationJob.id;

    this.jobDisplay.syncValidation(this.jobId);
  }
}
