<div class="filter-files">
  <input id="filter"
  type="text"
  spellcheck = false
  class="clr-input"
  (keyup)="onTypingFilter($event)"
  placeholder="Filter files">
</div>

<clr-datagrid [(clrDgSingleSelected)]="selectedFile">
  <clr-dg-column>Name</clr-dg-column>
  <clr-dg-row *clrDgItems="let file of filteredFiles"
    [clrDgItem]="file"
    (click)="selectFile($event)"
    [attr.data-location]="file.location">
    <clr-dg-cell>{{file.name}}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} items
    <clr-dg-pagination #pagination
      [clrDgPageSize]="3"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>