/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalysisService } from '../../services/analysis.service';
import {HttpClient} from '@angular/common/http';
import {VmwClarityTheme, VmwClarityThemeService} from '@vmw/ngx-utils';
// import { Range } from 'ngx-monaco-editor';

@Component({
  selector: 'vmw-apiv-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.css'],
})

export class FileDisplayComponent implements OnInit {

  public VmwClarityTheme = VmwClarityTheme;

  jobId: string;
  fileType: string;
  fileContent: string;
  line: number;

  editor: any;

  lightEditorOptions = {automaticLayout : true, theme: 'vs-light', language: 'text', readOnly: true };
  darkEditorOptions = {automaticLayout : true, theme: 'vs-dark', language: 'text', readOnly: true };

  constructor(private analysisService: AnalysisService,
              private http: HttpClient,
              private route: ActivatedRoute,
              public themeService: VmwClarityThemeService) {

    this.route.paramMap.subscribe(params => {
      this.jobId = params.get('jobId');
      this.fileType = params.get('fileType');

      this.displayFile();
    });

    this.route.queryParams.subscribe(params => {
      const line = params['line'];
      if (line) {
         this.line = parseInt(line,10);
         //console.log('this.line=',this.line);
      }
    });
  }

  ngOnInit() {
  }

  onInit(editor) {
      this.editor = editor;

    //  console.log('FileDisplayComponent.onInit: editor=', this.editor);

    //console.log('FileDisplayComponent.onInit: model=', this.editor.getModel());

    // this.editor.getModel().onDidChangeContent((event) => {
    //    console.log('FileDisplayComponent onDidChangeContent callback.');
    //    this.syncToLine();
    //  });

    //this.editor.getModel().onDidChangeDecorations((event) => {
    //  console.log('FileDisplayComponent onDidChangeDecorations callback.');
    //});

    //this.editor.getModel().onDidChangeOptions((event) => {
    //  console.log('FileDisplayComponent onDidChangeOptions callback.');
    //});

    //this.editor.getModel().onDidChangeLanguage((event) => {
    //  console.log('FileDisplayComponent onDidChangeLanguage callback.');
    //});

    this.editor.getModel().onDidChangeLanguageConfiguration((event) => {
      console.log('FileDisplayComponent onDidChangeLanguageConfiguration callback.');
      this.syncToLine();
    });

    /*
    this.editor.defineTheme('myTheme', {

      base: 'vs',
      inherit: true,
      rules: [{ background: 'EDF9FA' }],
      colors: {
        'editor.foreground': '#000000',
        'editor.background': '#EDF9FA',
        'editorCursor.foreground': '#8B0000',
        'editor.lineHighlightBackground': '#0000FF20',
        'editorLineNumber.foreground': '#008800',
        'editor.selectionBackground': '#88000030',
        'editor.inactiveSelectionBackground': '#88000015'
      }
    });
    this.editor.setTheme('myTheme');
    */
  }

  scrollToLine(line: number) {
    if (this.editor) {
      console.log('scrollToLine', line);
      //let editorPos = {column: 1, lineNumber: line};
      this.editor.revealLineInCenter(line);
      var decorations = this.editor.deltaDecorations([], [
        { range: new monaco.Range(line,1,line,200), options: { isWholeLine: true, linesDecorationsClassName: 'myLineDecoration' }},
        { range: new monaco.Range(line,1,line,200), options: { inlineClassName: 'myInlineDecoration' }},
      ]);

    }
  }

  syncToLine() {
    if (this.line) {
      this.scrollToLine(this.line);
    }
  }

  displayFile(): void {
    console.log('FileDisplayComponent.displayFile. id=', this.jobId, ' type=', this.fileType);

    // expected values for fileType:  spec, config, log

    if ('log' === this.fileType) {
      this.lightEditorOptions.language = 'text';
    } else if ('html' === this.fileType) {
      this.lightEditorOptions.language = 'html';
    } else if ('junit' === this.fileType) {
      this.lightEditorOptions.language = 'xml';
    } else {
      this.lightEditorOptions.language = 'json';
    }
    this.darkEditorOptions.language = this.lightEditorOptions.language;

    this.fileContent = null;

    const url = this.analysisService.getFileUrl(this.jobId, this.fileType);

    console.log('FileDisplayComponent.displayFile calling get', url);
    this.http.get(url,{responseType:'text'}).subscribe(
      response => {
        console.log('FileDisplayComponent.displayFile setting fileContent');
        this.fileContent = response as string;
        this.syncToLine();
      }
    );
  }
}
