import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.userService.getUser();
    if (user) {
      //return !this.userService.isCurrentSessionExpired();
      return true;
    }

    // No user credentials. Redirect to login page.
    this.userService.redirectToLogin(state.url);
    return false;
  }
}
