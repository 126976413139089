/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */
import { Component, OnInit } from '@angular/core';
import {ClrDatagridStateInterface} from "@clr/angular";

import {AnalysisService} from '../../services/analysis.service';
import {UserService} from '../../services/user.service';
import {ApivValidationJob} from '../../../apiv-client/Apiv/types';
import * as moment from 'moment';
import {ApivValidationJobs} from '../../../apiv-client/Apiv/types/ApivValidationJobs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'vmw-apiv-my-validations',
  templateUrl: './my-validations.component.html',
  styleUrls: ['./my-validations.component.css']
})
export class MyValidationsComponent {
  public total: number;
  public page: number;
  public validations: any = null;
  public loading = false;

  private user_filter: string = null;
  private state_filter: string = null;
  private all_users = false;

  constructor(private userService: UserService, private analysisService: AnalysisService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.user_filter = params['user_filter'];
      this.state_filter = params['state_filter'];
      this.state_filter = params['state_filter'];
      this.all_users = 'true' === params['all_users'];
    });
  }

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;
    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    // let filters:{[prop:string]: any[]} = {};
    // if (state.filters) {
    //   for (let filter of state.filters) {
    //     let {property, value} = <{property: string, value: string}>filter;
    //     filters[property] = [value];
    //   }
    // }

    if (!state.page) {
      state.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }
    // this.inventory.filter(filters)
    //   .sort(<{by: string, reverse: boolean}>state.sort)
    //   .fetch(state.page.from, state.page.size)
    //   .then((result: FetchResult) => {
    //     this.users = result.users;
    //     this.total = result.length;
    //     this.loading = false;
    //   });
    this.analysisService.getValidationStatuses(this.all_users, this.user_filter, this.state_filter, state.page.from, state.page.size).subscribe(
      resultResponse => {
        // resultResponse is ApivValidationJobs which has support for pagination
        this.validations = resultResponse.data as Array<ApivValidationJob>;
        this.total = resultResponse.total;
        this.loading = false;
      });
  }

  getValidationStateUrl(validation: ApivValidationJob): string {
    if (validation.state === 'ERROR') {
      return '<a href="' + this.getLogUrl(validation.id) + '" target="_blank">ERROR</a>';
    } else if (validation.state === 'FINISHED') {
        return 'OK';
    } else {
        return validation.state;
    }
  }

  getLogUrl(jobId: string) : string {
    return this.analysisService.getLogUrl(jobId);
  }
  getSpecUrl(jobId: string) : string {
    return this.analysisService.getSpecUrl(jobId);
  }
  getConfigUrl(jobId: string) : string {
    return this.analysisService.getConfigUrl(jobId);
  }
  getJobResultsUrl(jobId: string) : string {
    return this.analysisService.getJobResultsUrlRelative(jobId);
  }
  getDuplicateOfHtml( job: ApivValidationJob): string {
    return this.analysisService.getDuplicateOfHtml(job.duplicate_of_id);
  }

  toDurationSeconds(start: string, finish: string) {
    if (start && finish) {
      const startMoment = moment(start);
      const finishMoment = moment(finish);
      const duration = moment.duration(finishMoment.diff(startMoment));
      return duration.asSeconds().toString();
    } else {
      return "Running...";
    }
  }
}
