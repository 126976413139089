import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { JobDisplayPageComponent } from './components/job-display-page/job-display-page.component';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { ConfigDisplayComponent } from './components/config-display/config-display.component';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from './components/login/login.component';
import {NewValidationPageComponent} from './components/new-validation-page/new-validation-page.component';
import {MyValidationsComponent} from './components/my-validations/my-validations.component';
import {StandardDisplayComponent} from './components/standard-display/standard-display.component';

export const ROUTES: Routes = [
  { path: '', component: HomeComponent },
  { path: 'new', component: NewValidationPageComponent, canActivate: [ AuthGuard ] },
  { path: 'jobs/:jobId', component: JobDisplayPageComponent, canActivate: [ AuthGuard ] },
  { path: 'jobs/:jobId/:fileType', component: FileDisplayComponent, canActivate: [ AuthGuard ] },
  { path: 'configs/:configName', component: ConfigDisplayComponent, canActivate: [ AuthGuard ] },
  { path: 'configs/:configName/:fileType', component: ConfigDisplayComponent, canActivate: [ AuthGuard ] },
  { path: 'vmware-standard', component: StandardDisplayComponent, canActivate: [ AuthGuard ] },
  { path: 'validations', component: MyValidationsComponent, canActivate: [ AuthGuard ] },
  { path: 'validations/:jobId', component: JobDisplayPageComponent, canActivate: [ AuthGuard ] },
  { path: 'validations/:jobId/:fileType', component: FileDisplayComponent, canActivate: [ AuthGuard ] },
  { path: 'developer', loadChildren: () => import('./components/apiv-dev-center/apiv-dev-center.module').then(m => m.ApivDevCenterModule), canActivate: [ AuthGuard ] },
  { path: 'login/authenticate', component: LoginComponent }
];

export const ROUTING: ModuleWithProviders<RouterModule> = RouterModule.forRoot(ROUTES, { useHash: false });

