/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component } from '@angular/core';
import { StandardService } from '../../services/standard.service';

@Component({
  selector: 'vmw-apiv-standard-display',
  templateUrl: './standard-display.component.html',
  styleUrls: ['./standard-display.component.css'],
})

export class StandardDisplayComponent {

  public standard_requirements: any[];

  constructor(private standardService: StandardService) {
    this.standard_requirements = this.standardService.getStandardRequirements();
  }

  getRequirementUrlForId(reqId: string): string {
    return this.standardService.getRequirementUrlForId(reqId);
  }

  getValidationUrl(validationName: string): string {
    const url = this.standardService.getValidationUrl(validationName);
    if (url) {
      return '<a href="' + url + '" target="_blank">' + validationName + '</a>';
    } else {
      return '<i>N/A</i>';
    }
  }
}
