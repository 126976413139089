import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private bypass_uri_check = null;

  constructor(private http: HttpClient, private environment: AppConfigService) {
  }

  getBypassUrlCheck(): boolean {
    if (this.bypass_uri_check == null) {
      // lazy init. check the host to see if we are bypassing the URI check.  This is only done for service instances
      // that are localhost (development) and staging right now.
      if (this.environment.config.authApiUrl &&
        (this.environment.config.authApiUrl.includes('staging') || this.environment.config.authApiUrl.includes('localhost'))) {
        this.bypass_uri_check = true;
      } else {
        this.bypass_uri_check = false;
      }
    }
    return this.bypass_uri_check;
  }

  /**
   * check the auth service query argument.  May be empty string that is returned or may be a query argument include leading & char
   * @returns {string}
   */
  getUriCheckQueryArg(isOtherArgs: boolean): string {
    if (this.getBypassUrlCheck()) {
      if (isOtherArgs) {
        return '&bypass_uri_check=true';
      } else {
        return '?bypass_uri_check=true';
      }
    } else {
      return '';
    }
  }

  authenticate(code: string, clientId: string, redirectUri: string) {
    // console.log('AuthService.authenticate clientId=', clientId, ' redirectUri=', redirectUri);
    let data = null;
    if (this.getBypassUrlCheck()) {
      data = {grant_type: 'authorization_code', code: code, client_id: clientId, bypass_uri_check: true, redirect_uri : redirectUri};
    } else {
      data = {grant_type: 'authorization_code', code: code, client_id: clientId, redirect_uri : redirectUri};
    }
    // console.log("data about to be passed to post:",data);
    return this.http.post(`${this.environment.config.authApiUrl}/tokens`, data);
  }

  refreshToken(token: string) {
    const data = {grant_type: 'refresh_token', refresh_token: token};
    return this.http.post(`${this.environment.config.authApiUrl}/tokens`, data);
  }
}
