// tslint:disable

import { ApivConfigScript } from './ApivConfigScript';

export class ApivValidationConfig {
    public name?: string;
    public description?: string;
    public normalize_spec?: boolean;
    public contacts?: Array<string>;
    public extends?: string;
    public scripts?: Array<ApivConfigScript>;
    public validators?: Object;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.scripts) {
            this.scripts = this.scripts.map(item => new ApivConfigScript(item));
        }
    }
}
