<clr-header class="header-6">

  <div class="branding">
    <a href [routerLink]="['/']" class="nav-link">
      <span class="vmware-code-icon"></span>
    </a>
  </div>

  <div class="logo">
    <a href [routerLink]="['/']"  class="nav-link">
      <p class="name">VMware API Validator</p>
    </a>
  </div>

  <div class="header-nav">
    <a routerLink="/new" routerLinkActive="active" class="nav-link nav-text">New Validation</a>
  </div>

  <div class="header-nav">
    <a routerLink="/validations" routerLinkActive="active" class="nav-link nav-text">My Validations</a>
  </div>

  <div class="header-nav">
    <a routerLink="/developer" routerLinkActive="active" class="nav-link nav-text">Developer Center</a>
  </div>

  <div class="header-nav">
    <a routerLink="/vmware-standard" routerLinkActive="active" class="nav-link nav-text">VMware Standard Coverage</a>
  </div>

  <!--
  <div class="header-nav" *ngIf="user">
    <a class="nav-link nav-text" href="javascript://" (click)="openFeedbackForm=true">Provide Feedback</a>
  </div>
  -->

  <div class="header-actions">

    <a *ngIf="!user"
       href="javascript://"
       (click)="login()"
       class="nav-link nav-text">
      Sign In
    </a>
    <clr-dropdown *ngIf="user">
      <button class="nav-text" clrDropdownTrigger>
        {{user?.email}}
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <button (click)="logout()" clrDropdownItem>Log out</button>
      </clr-dropdown-menu>
    </clr-dropdown>

    <a class="nav-link nav-icon theme-select" href="javascript://" *ngIf="themeService.theme === VmwClarityTheme.Dark"
       (click)="themeChanged(VmwClarityTheme.Light)">
      <clr-icon shape="sun" class="is-solid"></clr-icon>
    </a>
    <a class="nav-link nav-icon theme-select" href="javascript://" *ngIf="themeService.theme === VmwClarityTheme.Light"
       (click)="themeChanged(VmwClarityTheme.Dark)">
      <clr-icon shape="moon" class="is-solid"></clr-icon>
    </a>
  </div>

</clr-header>
<!--
<esp-common-feedback [fsClientId]="FEEDBACK_CLIENT_ID"
                     [fsClientName]="'API Validator'"
                     [(fsOpen)]="openFeedbackForm" [fsSize]="'lg'"
                     [fsServiceHost]="FEEDBACK_URL"></esp-common-feedback>
-->


