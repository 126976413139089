/**
 * Copyright 2018-2019 VMware Inc, All Rights Reserved.
 * author(s): Aaron Spear aspear@vmware.com
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './job-display-page.component.html',
  styleUrls: ['./job-display-page.component.css'],
})

export class JobDisplayPageComponent {
  jobId: string = null;
  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.jobId = params.get('jobId');
    });
  }
}
