<ng-container *ngIf="loading;then isLoading else loadingComplete">
</ng-container>

<ng-template #isLoading>
  <div class="vcode-spinner">
    <span class="spinner">
      Loading...
    </span>
  </div>
</ng-template>

<ng-template #loadingComplete>
  <div class="filter-apis">
    <input id="filter"
      type="text"
      (keyup)="onTypingFilter($event)"
      placeholder="Filter Apis">
  </div>

  <!-- <clr-datagrid [(clrDgSingleSelected)]="selectedApi" [clDgRowSelection]="true"> -->
  <clr-datagrid [(clrDgSingleSelected)]="selectedApi" >
    <clr-dg-column>Name</clr-dg-column>
    <clr-dg-column>Version</clr-dg-column>
    <clr-dg-column>Last Modified</clr-dg-column>
    <clr-dg-row *clrDgItems="let api of filteredApis"
      [clrDgItem]="api"
      (click)="selectApi($event)"
      [attr.data-id]="api.id"  >
      <clr-dg-cell>{{api.name}}</clr-dg-cell>
      <clr-dg-cell>{{api.version}}</clr-dg-cell>
      <clr-dg-cell>{{api.lastModified}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} items
      <clr-dg-pagination #pagination
        [clrDgPageSize]="10"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>

</ng-template>
