<p>
The VMware REST Standard lives on Confluence at <a href="https://confluence.eng.vmware.com/pages/viewpage.action?spaceKey=Standards&title=REST" target="_blank">https://confluence.eng.vmware.com/pages/viewpage.action?spaceKey=Standards&title=REST</a>.
 This page presents a summary of the requirements along with links to the standard itself as well as to the rules that currently are used to validate standard compliance.
 You will note that there are many rules that are not covered by existing validations.  In some cases it is not possible to test for compliance via
  static validation. In others, additional validations have simply not been written yet.
</p>

<br/>
<div>
  <table class="table table-compact" style="margin-top: 0">
    <thead>
    <tr>
      <th class="left standard-id">Req ID</th>
      <th class="left standard-summary">Summary</th>
      <th class="left standard-type">Type</th>
      <th class="left standard-rule">Covered By Rule</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let req of standard_requirements">
        <td class="left"><a href="{{getRequirementUrlForId(req.id)}}" target="_blank">{{req.id}}</a></td>
        <td class="left">{{req.summary}}</td>
        <td class="left">{{req.type}}</td>
        <td class="left"><div [innerHTML]="getValidationUrl(req.validation)"></div></td>
      </tr>
    </tbody>
  </table>
</div>

