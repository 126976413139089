import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {build_info} from '../../environments/build-info';

@Injectable()
export class AppConfigService {
  private appConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    // console.log('loading /assets/config.json');
    return this.http.get('/assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
        console.log("appConfig loaded:",data);
      });
  }

  get config() {
    return this.appConfig;
  }

  get buildInfo() {
    if (build_info) {
      try {
        return build_info;
      } catch {
      }
    }
    // In dev the file might not exist:
    return { version: '0.0.0', revision_short: 'dev', revision_long: 'dev', branch: 'dev', build_time: new Date().toISOString(), build_id: 'dev', git_version: 'dev', app_version: 'dev' };
  }
}
