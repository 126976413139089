import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CLIENT_ID, REDIRECT_URI, STATE } from '../app.config';
import {AppConfigService} from './app-config.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private SESSION_KEY: string = 'esp-api-client-session';
  private jwtService: JwtHelperService;
  private currentSession: BehaviorSubject<any>;
  private bypass_uri_check = null;

  constructor(private location: Location, private http: HttpClient, private environment: AppConfigService, private authService: AuthService) {
    this.jwtService = new JwtHelperService();
    let session = null;
    try {
      session = JSON.parse(localStorage.getItem(this.SESSION_KEY));
    } catch (e) {
    }
    this.currentSession = new BehaviorSubject(session);

    if (this.isCurrentSessionExpired()) {
      this.currentSession = new BehaviorSubject(null);
      localStorage.removeItem(this.SESSION_KEY);
    }
  }

  getCurrentSession(): BehaviorSubject<any> {
    return this.currentSession;
  }

  getUser() {
    const session = this.currentSession.getValue();
    return session ? session.user : null;
  }

  getAccessToken() {
    const session = this.currentSession.getValue();
    return session ? session.access_token : null;
  }

  getRefreshToken() {
    const session = this.currentSession.getValue();
    return session ? session.refresh_token : null;
  }

  saveCurrentSession(session) {
    session.user = this.jwtService.decodeToken(session.access_token);
    console.log('current user=', session.user);
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(session));
    this.currentSession.next(session);
  }

  clearCurrentSession() {
    localStorage.removeItem(this.SESSION_KEY);
    this.currentSession.next(null);
  }

  isTokenExpired(token: string) {
    return this.jwtService.isTokenExpired(token);
  }

  isCurrentSessionExpired() {
    const currentToken = this.getAccessToken();
    if (! currentToken) {
      return true;
    } else {
      return this.jwtService.isTokenExpired(currentToken);
    }
  }

  logout() {
    this.http.post(`${this.environment.config.authApiUrl}/logout`, null).pipe(
      finalize(() => {
        this.clearCurrentSession();
        this.redirectToHome();
      })
    ).subscribe();
  }

  redirectToLogin(returnUrl: string = '/') {

    console.log('redirecting to login');

    const redirectUri = window.location.origin + this.location.prepareExternalUrl(REDIRECT_URI);

    const  bypassUriCheckQueryArg = this.authService.getUriCheckQueryArg(true);

    const data = {returnUrl: returnUrl};
    const state = btoa(JSON.stringify(data));

    window.location.href = `${this.environment.config.authApiUrl}/authorize?response_type=code&client_id=${CLIENT_ID}${bypassUriCheckQueryArg}&redirect_uri=${redirectUri}&state=${state}`;
  }

  redirectToHome() {
    console.log('redirecting to home');
    const redirectUri = window.location.origin + this.location.prepareExternalUrl('/');
    window.location.href = `${redirectUri}`;
  }
}
