<h3 style="margin-top: 0px!important;">Select the configuration for your validation</h3>

The individual validations that are performed are controlled by a configuration file.  You can use a custom configuration file via the "Load Local Configuration File" button below, or select one of
the built in shared configurations.  Soon VMware teams will be able to use shared configurations that they define and maintain (for a given product with a legacy API for instance).
<br>
<br>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Configuration File Format Details</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <h4>Configuration file format</h4>
      The configuration file is JSON and supports configuration of multiple different implementation validators.
      Currently there are two working validators, one for the <a href="https://github.com/IBM/openapi-validator">lint-openapi</a> tool, and one for <a href="https://github.com/stoplightio/spectral">spectral</a>.
      Each validator has its own section in the configuration file with a config_file section that is in a validator specific format.

      <h4>Configuration syntax help</h4>
      <ul>
        <li><a href="https://github.com/IBM/openapi-validator/blob/master/README.md#configuration-file" target="_blank">Click here for the upstream documentation on the supported lint-openapi configuration options.</a></li>
        <li><a href="https://meta.stoplight.io/docs/spectral/docs/guides/4-custom-rulesets.md" target="_blank">Click here for the upstream documentation for Spectral rules and rulesets.</a></li>
      </ul>

      <h4>Example configurations</h4>
      <ul>
        <li><a href="/configs/VMware-Standard-lint-openapi" target="_blank">Initial attempt at a VMware Standard configuration using the 'lint-openapi' validation tool.</a></li>
        <li><a href="/configs/VMware-Standard-camelCase-lint-openapi" target="_blank">Same as above, but allowing camelCase identifiers.</a></li>
        <li><a href="/configs/VMware-Standard-Spectral" target="_blank">Initial attempt at a VMware Standard configuration using the 'Spectral' validation tool.</a></li>
        <li><a href="/configs/default" target="_blank">Download the 'default' configuration here (uses lint-openapi).</a></li>
      </ul>

      <h4>Coming features</h4>
      <ul>
        <li><b>Configuration inheritance</b>: We will be using an 'extends' element which will allow referring to another configuration by name and then simply overriding a minimal subset of settings as you please.</li>
        <li><b>Rule filters</b>: There will be support for defining URLs with wildcards (or maybe regex) for path segments to ignore validation results on.</li>
      </ul>

    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<vmw-apiv-create-validation
  (displayValidation)="onDisplayValidation($event)">
</vmw-apiv-create-validation>
