<div *ngIf="!validations">
  <span *ngIf="loading" class="spinner spinner-inline"></span>
</div>

<!-- TODO figure out how filtering works in Datagrid using  [clrDgField]="'created_time'" -->
<div>
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column class="job-id-column">Job ID</clr-dg-column>
    <clr-dg-column class="client-tracking-column">Tracking Token</clr-dg-column>
    <clr-dg-column class="duplicate-of-column">Duplicate Of</clr-dg-column>
    <clr-dg-column class="job-completed-column">Job OK?</clr-dg-column>
    <clr-dg-column class="api-name-column">API Name</clr-dg-column>
    <clr-dg-column class="error-column">Error Count</clr-dg-column>
    <clr-dg-column class="warning-colmn">Warning Count</clr-dg-column>
    <clr-dg-column class="info-column">Info Count</clr-dg-column>
    <clr-dg-column class="created-by-column">Created By</clr-dg-column>
    <clr-dg-column class="created-time-column">Created Time</clr-dg-column>

    <clr-dg-placeholder>No validations found</clr-dg-placeholder>

    <clr-dg-row *ngFor="let validation of validations" [clrDgItem]="validation">
      <clr-dg-cell><a href="{{getJobResultsUrl( validation.id )}}">{{validation.id}}</a></clr-dg-cell>
      <clr-dg-cell>{{validation.client_tracking_token}}</clr-dg-cell>
      <clr-dg-cell><div [innerHTML]="getDuplicateOfHtml(validation)"></div></clr-dg-cell>      
      <clr-dg-cell><div [innerHTML]="getValidationStateUrl(validation)"></div></clr-dg-cell>
      <clr-dg-cell>{{validation.api_name}}</clr-dg-cell>
      <clr-dg-cell>{{validation.result_error_count}}</clr-dg-cell>
      <clr-dg-cell>{{validation.result_warning_count}}</clr-dg-cell>
      <clr-dg-cell>{{validation.result_info_count}}</clr-dg-cell>
      <clr-dg-cell>{{validation.created_by}}</clr-dg-cell>
      <clr-dg-cell>{{validation.created_time | date:'MMM d, y, h:mm:ss.SSS a'}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page"  [clrDgTotalItems]="total">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Validations per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} validations
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<!--


-->
