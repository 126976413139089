
// this file is automatically generated by build.info.ts script, do not modify.
export const build_info = {
  version: '10.4.0',
  revision_short: '54f8cb4',
  revision_long: '54f8cb419311729cfbbabf7127d0e0956b18ca14',
  branch: 'HEAD',
  git_version: 'v20210915-2-2-g54f8cb4',
  build_time: '2021-11-17T21:36:47+0000',
  build_id: '3104521',
  app_version: '10.4.0'
};